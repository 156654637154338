import React from 'react'
import Blog from './Blog'
import { Link } from 'gatsby'

export default function Blogs({ blogs, title, showLink }) {
    return (
        <div className="blogs-wrapper">
            <h4 className="text-primary">{title}</h4>
            <div className="blogs-container">
                {
                    blogs.map(blog => (
                        <Blog key={blog.strapiId} data={blog} />
                    ))
                }
            </div>
            {showLink && (
                <p>
                    <Link className="btn btn-full" to="/blog">Blog</Link>
                </p>
            )}
        </div>
    )
}
