import React from 'react'
import {Link} from 'gatsby';
import Image from 'gatsby-image';
export default function Blog({data}) {
    const {title, thumbnail, date, category, description, slug} = data;
    return (
        <Link className="blog-item" to={`/blog/${slug}`} >
            <article className="blog-content">
                <Image fluid={thumbnail.childImageSharp.fluid} className="blog-thumbnail"></Image>
                <div className="blog-text">
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
                <div className="blog-footer">
                    <p>{category}</p>
                    <span>{date}</span>
                </div>
            </article>
        </Link>
    )
}
